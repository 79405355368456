.form-user {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
.form-user .user-col-right {
    width: 45%;
}
.form-user .user-col-left {
    width: 45%;
}
.form-user .user-col-mota {
    width: 100%;
}
